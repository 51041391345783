import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import {
  MONITORING_ENDPOINTS,
} from '../helpers/urls';

import Header from '../components/header';


import auth from '../helpers/auth';
import { Context } from '../contexts/Context';
import Graph from "react-graph-vis";

const TxDetails = (props) => {
  // CONTEXT VARIABLES, VARIABLES THAT ARE AVAILABLE ALL THROUGH OUT THE SITE
  const {   
    updateIsLoadingState,
    isProfileUpdated,    
  } = useContext(Context);

  const invDetailsUrl = MONITORING_ENDPOINTS.txInvestigation;
  // const [nodes, setNodes] = useState([]);
  // const [edges, setEdges] = useState([]);
  const [graph, setGraph] = useState({
    nodes: [],
    edges: []
  });
  const [options, setOptions] = useState({});
  const [events, setEvents] = useState({});
  const [hasData, setHasData] = useState(false);

    // ACCESS AVAILABLE URL PARAMETER
    const {
      match: { params },
    } = props;
   
      const getData = () => {
      updateIsLoadingState(true)
       axios
      .get(invDetailsUrl + 'transaction=' + params.hash, auth.getAuthConfig())
      .then(({ data }) => { 
          if (data.content.nodes && data.content.edges){
            console.log(data);      
            
            let edges = data.content.edges;
            // console.log(edges);

            const nodes  = data.content.nodes.map(item => {              
              item.label = item.label.toString();
              item.title = `<p style="background: ${item.color}" >Hash: ${item.title} <br/>
                            Risk Score: ${item.label} <br/>
                            Flag: ${item.flags} <br/>
                            Type: ${item.type} <br/>
                            Hop:  ${item.hop}
                            </p>`;  
          
              return item;
          })

          let seen = new Set();
          var hasDuplicates = nodes.some(function(currentObject) {
              return seen.size === seen.add(currentObject.id).size;
          });

          console.log(hasDuplicates);
          if (!hasDuplicates)
           {
            setGraph({
              nodes: nodes,
              edges: edges
            });
            
            setOptions({
              layout: {
                hierarchical: true
              },
              edges: {
                color: "#000000"
              },
              autoResize: true,
              height: '100%',
              width: '100%',
              interaction:{hover:true}
            });
            
            setEvents({
              select: function(event) {
                var { nodes, edges } = event;
                console.log("Selected nodes:");
                console.log(nodes);
                console.log("Selected edges:");
                console.log(edges);
              },
              hoverNode: function(event){      
                var { node } = event;
                console.log(node);
              }              
            });  
              
              setHasData(true);
           }
           else{
            setHasData(false);
           }        
          
            updateIsLoadingState(false)
            
          }
          else{            
            setHasData(false);
            updateIsLoadingState(false)
          
          }
      })
      .catch((error) => {
        console.log(error);       
        setHasData(false);
        updateIsLoadingState(false)
      });
  }

  useEffect(() => {
    getData()
  }, [isProfileUpdated])
 

  return (
    <>
    <div className="case-management">
      <Header />
      <div className="case-management-content">       
        <div className="case-table">
            {<Graph className="noGraph" graph={graph} options={options} events={events} style={{ height: "850px"}} />}
            
        </div>
      </div>
    </div>
    </>
  );
};
 
export default withRouter(TxDetails);
